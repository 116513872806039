import { Box, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { formatCurrency, numberToPercent } from "../../../shared/utils/formatUtils";
import { ProviderProgramRecord } from "../../../shared/types/types";
import { Color } from "../../../shared/types/enums";
import infoIcon from "../../../shared/assets/icons/info.svg";

type Props = {
  setWhatIsROIOpen: React.Dispatch<React.SetStateAction<boolean>>;
  program: ProviderProgramRecord;
  personalizedCost: number | null;
};

const formatROIColor = (roi: number | null) => {
  if (!roi) {
    return "#aaa";
  } else if (roi >= 100000) {
    return Color.MINT;
  } else if (roi >= 0) {
    return Color.ORANGE_700;
  } else {
    return Color.PINK;
  }
};

const formatROILabel = (roi: number | null) => {
  if (!roi) {
    return "Unknown";
  } else if (roi >= 100000) {
    return "Good";
  } else if (roi >= 0) {
    return "Okay";
  } else {
    return "Poor";
  }
};

const formatPercentColor = (rate: number | null) => {
  if (!rate) {
    return "#aaa";
  } else if (rate > 0.8) {
    return Color.MINT;
  } else if (rate > 0.5) {
    return Color.ORANGE_700;
  } else {
    return Color.PINK;
  }
};

const formatPercentLabel = (rate: number | null) => {
  if (!rate) {
    return "Unknown";
  } else if (rate > 0.8) {
    return "Good";
  } else if (rate > 0.5) {
    return "Okay";
  } else {
    return "Poor";
  }
};

type CalcROIEarnings = {
  earnings: number | null;
  cost: number | null;
  durationYears: number | null;
  durationMonths: number | null;
  totalCost: number | null;
};

const BASELINE_EARNINGS = 30000;

const calcROI = ({ earnings, durationYears, durationMonths, totalCost }: CalcROIEarnings) => {
  if (!earnings || totalCost === null) return null;
  if (!durationYears && !durationMonths) return null;
  if (!durationYears) durationYears = 0;
  if (!durationMonths) durationMonths = 0;
  return (earnings - BASELINE_EARNINGS) * 10 - totalCost;
};

const determineROI = (program: ProviderProgramRecord, personalizedCost: number | null) => {
  const roi = program.programROI
    ? program.programROI
    : program.providerROI
    ? program.providerROI
    : null;
  if (personalizedCost === null) {
    return roi ? formatCurrency({ amount: roi }) : "Unknown";
  } else {
    const personalizedROI = calcROI({
      earnings: program.fiveYearEarnings
        ? program.fiveYearEarnings
        : program.program1yrEarnings
        ? program.program1yrEarnings
        : program.provider4yrEarnings
        ? program.provider4yrEarnings
        : program.provider1yrEarnings,
      cost: program.academicCostsPerYear,
      durationYears: program.durationYears,
      durationMonths: program.durationMonths,
      totalCost: personalizedCost,
    });
    return personalizedROI ? formatCurrency({ amount: personalizedROI }) : "Unknown";
  }
};

const QualityMeasures = ({ program, setWhatIsROIOpen, personalizedCost }: Props) => {
  const completionRate = program.completionRate ? program.completionRate : null;

  const roi = program.programROI
    ? program.programROI
    : program.providerROI
    ? program.providerROI
    : null;
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: Color.SURFACE_GREEN,
              textAlign: "left",
              borderRadius: 1,
              pl: 2,
              m: 0,
              height: 110,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: 34, color: "#171918", p: 0, m: 0 }}
              variant="h6"
            >
              {determineROI(program, personalizedCost)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                pb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 12, mt: -1 }} variant="h5">
                ROI: Earnings After 10 Years
              </Typography>
              <img
                src={infoIcon}
                alt="info"
                width="12px"
                height="12px"
                style={{ cursor: "pointer", marginLeft: "4px", marginTop: "-8px" }}
                onClick={() => setWhatIsROIOpen(true)}
              />
            </Box>

            <Box sx={{ with: "fit-content" }}>
              <Chip
                sx={{ backgroundColor: formatROIColor(roi), color: "#fff", p: 0 }}
                label={formatROILabel(roi)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: Color.SURFACE_GREEN,
              textAlign: "left",
              borderRadius: 1,
              p: 0,
              m: 0,
              height: 110,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: 34, color: "#171918", p: 0, m: 0 }}
              variant="h6"
            >
              {completionRate ? numberToPercent({ number: completionRate }) : "Not Known"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                pb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 12, mt: -1 }} variant="h5">
                Completion Rate
              </Typography>
            </Box>

            <Box sx={{ with: "fit-content" }}>
              <Chip
                sx={{ backgroundColor: formatPercentColor(completionRate), color: "#fff", p: 0 }}
                label={formatPercentLabel(completionRate)}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default QualityMeasures;
