import { Grid } from "@mui/material";
import StudentCard from "./StudentCard";
import MyWhy from "./MyWhy";
import Notes from "./Notes";
import PersonalityType from "./PersonalityType";
import AcademicsAndFinance from "./AcademicsAndFinance";
import CareersContainer from "./CareersContainer";
import ProgramsContainer from "./ProgramsContainer";
import JobDetailsDialog from "../../../shared/components/exploreCareers/JobDetailsDialog";

const StudentOverviewContainer = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={6}>
          <StudentCard />
          <MyWhy />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <AcademicsAndFinance />
        </Grid>
        <Grid item xs={12}>
          <PersonalityType />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CareersContainer />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ProgramsContainer />
        </Grid>
      </Grid>
      <JobDetailsDialog />
    </>
  );
};

export default StudentOverviewContainer;
