import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import {
  AlmaChat,
  AlmaChatRecord,
  BaseRecord,
  ChatEntry,
  StudentRecord,
} from "../../../shared/types/types";
import { currentStudentAlmaChatAtom, studentAlmaChatsAtom } from "../../../shared/recoil/almaAtoms";
import { Collection } from "../../../shared/types/enums";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import { buildChatEntry, getAlmaResponse } from "../../utils/studentAlmaUtils";

type Props = {
  chatHistory: ChatEntry[];
  setChatHistory: Dispatch<SetStateAction<ChatEntry[]>>;
};

const useSubmitChat = ({ chatHistory, setChatHistory }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const currentStudentAlmaChat = useRecoilValue(currentStudentAlmaChatAtom);
  const setStudentAlmaChats = useSetRecoilState(studentAlmaChatsAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { addFSDoc } = useAddFSDoc();
  const { submitLog } = useLogger();
  const { handleError } = useHandleError();

  const logChatSubmission = useCallback(
    (loggedInStudent: StudentRecord) => {
      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.SUBMITTED_STUDENT_ALMA_CHAT,
        changeLog: `User ${loggedInStudent.id} added a message with Alma`,
        file: "useChatContainer.ts",
        error: null,
      });
    },
    [submitLog]
  );

  const handleChatError = useCallback(
    (error: any) => {
      handleError({
        error,
        snackbarMessage:
          "Alma had a hard time responding to that chat, please refresh and try again",
        eventType: LogEventType.SUBMITTED_STUDENT_ALMA_CHAT_ERROR,
        file: "useChatContainer.ts",
      });
    },
    [handleError]
  );

  const addNewChatRecord = useCallback(
    async ({
      chatHistory,
      newEntry,
      responseToSave,
      loggedInStudent,
    }: {
      chatHistory: ChatEntry[];
      newEntry: ChatEntry;
      responseToSave: ChatEntry;
      loggedInStudent: StudentRecord;
    }) => {
      if (chatHistory.length < 2) return;
      const startingChats = [chatHistory[0], chatHistory[1]];
      const now = new Date().toDateString();
      const data: AlmaChat = {
        chatHistory: [...startingChats, newEntry, responseToSave],
        studentId: loggedInStudent.id,
        current: true,
        schoolId: loggedInStudent?.schoolId ?? "",
        districtId: loggedInStudent?.districtId ?? "",
        name: now,
      };
      const meta: BaseRecord = await addFSDoc({
        col: Collection.ALMA_CHATS,
        data: data,
      });
      return { ...data, ...meta };
    },
    [addFSDoc]
  );

  const updateNewChatRecord = useCallback(
    async ({
      currentStudentAlmaChat,
      newEntry,
      responseToSave,
    }: {
      currentStudentAlmaChat: AlmaChatRecord;
      newEntry: ChatEntry;
      responseToSave: ChatEntry;
    }) => {
      const data = {
        chatHistory: [...currentStudentAlmaChat.chatHistory, newEntry, responseToSave],
      };

      await updateFSDoc({
        col: Collection.ALMA_CHATS,
        id: currentStudentAlmaChat.id,
        data: data,
      });
      return data;
    },
    [updateFSDoc]
  );

  const updateChatRecord = useCallback(
    async ({
      newEntry,
      responseToSave,
      loggedInStudent,
    }: {
      newEntry: ChatEntry;
      responseToSave: ChatEntry;
      loggedInStudent: StudentRecord;
    }) => {
      if (!currentStudentAlmaChat) {
        const record = await addNewChatRecord({
          chatHistory,
          newEntry,
          responseToSave,
          loggedInStudent,
        });
        if (record) setStudentAlmaChats([record]);
      } else {
        const data = await updateNewChatRecord({
          currentStudentAlmaChat,
          newEntry,
          responseToSave,
        });
        setStudentAlmaChats((prev) =>
          prev.map((chat) =>
            chat.id === currentStudentAlmaChat.id
              ? { ...chat, chatHistory: data.chatHistory }
              : chat
          )
        );
      }
    },
    [
      addNewChatRecord,
      chatHistory,
      currentStudentAlmaChat,
      setStudentAlmaChats,
      updateNewChatRecord,
    ]
  );

  const handleSubmit = useCallback(
    async (newMessage: string) => {
      if (!loggedInStudent) return;

      const newEntry = buildChatEntry(newMessage);
      const updatedChatHistory = [...chatHistory, newEntry];

      setChatHistory(updatedChatHistory);
      setIsLoading(true);

      try {
        const responseToSave = await getAlmaResponse({
          chatHistoryForAI: updatedChatHistory,
          newEntry,
          contextToAdd: "",
        });

        setChatHistory((prev) => [...prev, responseToSave]);

        await updateChatRecord({ newEntry, responseToSave, loggedInStudent });
        logChatSubmission(loggedInStudent);

        setMessage("");
      } catch (err) {
        handleChatError(err);
      } finally {
        setIsLoading(false);
        setMessage("");
      }
    },
    [
      chatHistory,
      handleChatError,
      logChatSubmission,
      loggedInStudent,
      setChatHistory,
      updateChatRecord,
    ]
  );

  return { handleSubmit, isLoading, message, setMessage };
};

export default useSubmitChat;
