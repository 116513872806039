import { Box, Button, MenuItem, MenuList, Typography } from "@mui/material";
import { useState } from "react";
import { Color } from "../../../shared/types/enums";
import exploreProgramsGraphic from "../../../shared/assets/static/explore-programs-graphic.svg";
import EditFinancialsDialog from "../efcDialog/EditFinancialsDialog";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";

type Props = {
  selectedMenuItem: "all" | "recommended";
  setSelectedMenuItem: (selectedMenuItem: "all" | "recommended") => void;
};

const ExploreProgramsSidebar = ({ selectedMenuItem, setSelectedMenuItem }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [openEditFinancialsDialog, setOpenEditFinancialsDialog] = useState(false);

  return (
    <>
      <Box sx={{ pt: 1 }}>
        <Typography variant="h4">Explore Programs</Typography>
        <Box
          sx={{
            pl: 2,
            height: "calc(100VH - 135px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MenuList>
            <MenuItem
              selected={selectedMenuItem === "all"}
              onClick={() => setSelectedMenuItem("all")}
              style={{
                padding: "16px",
                borderBottom: "1px solid #E0E0E0",
                fontSize: "16px",
                fontWeight: selectedMenuItem === "all" ? "bold" : "normal",
                borderLeft: selectedMenuItem === "all" ? `4px solid ${Color.MINT}` : "none",
              }}
            >
              All Programs
            </MenuItem>
            <MenuItem
              selected={selectedMenuItem === "recommended"}
              onClick={() => setSelectedMenuItem("recommended")}
              style={{
                padding: "16px",
                borderBottom: "1px solid #E0E0E0",
                fontSize: "16px",
                fontWeight: selectedMenuItem === "recommended" ? "bold" : "normal",
                borderLeft: selectedMenuItem === "recommended" ? `4px solid ${Color.MINT}` : "none",
              }}
            >
              Recommended Programs
            </MenuItem>
          </MenuList>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
            <Button variant="contained" fullWidth onClick={() => setOpenEditFinancialsDialog(true)}>
              {(loggedInStudent?.efc ?? 0) === 0
                ? "Get Personalized Costs and ROI"
                : "Update My Financial Information"}
            </Button>
            <img src={exploreProgramsGraphic} alt="explore-programs" width="100%" />
          </Box>
        </Box>
      </Box>
      <EditFinancialsDialog open={openEditFinancialsDialog} setOpen={setOpenEditFinancialsDialog} />
    </>
  );
};

export default ExploreProgramsSidebar;
