import { useEffect, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ProviderProgramRecord, ReviewRecord } from "../../types/types";
import useGetFSDoc from "../db/useGetFSDoc";
import { userTypeAtom } from "../../recoil/userAtoms";
import { Collection, FunctionName, UserType } from "../../types/enums";
import { allMatchedProgramsAtom, programsAtom } from "../../recoil/providerAndProgramAtom";
import { fetchData } from "../../utils/fetchUtils";
import { getProgramImage } from "../../utils/imageUtils";

type DescriptionModalType = {
  title: string;
  description: string;
  open: boolean;
};

type Props = {
  programId: string;
};

const useProgramDetailsDialog = ({ programId }: Props) => {
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<ProviderProgramRecord | null>(null);
  const [applyLink, setApplyLink] = useState<string | null>(null);
  const [getProgramTrigger, setGetProgramTrigger] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const { getFSDoc } = useGetFSDoc();

  const [reviews, setReviews] = useState<ReviewRecord | null>(null);

  const [descriptionModal, setDescriptionModal] = useState<DescriptionModalType>({
    title: "",
    description: "",
    open: false,
  });
  const programs = useRecoilValue(programsAtom);
  const allMatchedPrograms = useRecoilValue(allMatchedProgramsAtom);
  const userType = useRecoilValue(userTypeAtom);
  const navigate = useNavigate();
  const [programImage, setProgramImage] = useState<string | undefined>(undefined);

  const loadImage = useCallback(async (willowSubIndustryId: string | undefined | null) => {
    if (!willowSubIndustryId) return;
    try {
      const imageModule = await getProgramImage({ id: willowSubIndustryId });
      setProgramImage(imageModule.default);
    } catch (error) {
      console.error("Error loading image:", error);
      setProgramImage(undefined);
    }
  }, []);

  const fetchProgram = useCallback(
    async (programId: string) => {
      setLoading(true); // Start loading
      try {
        const now = new Date().getTime();
        console.log("Fetching program");
        const results = await fetchData({
          functionName: FunctionName.GET_PROGRAM_AND_PROVIDER_BY_ID,
          payload: { documentId: programId },
        });
        const { program } = (await results.json()) as { program: ProviderProgramRecord };
        console.log("Fetched program and it took this long", new Date().getTime() - now);
        setCurrentProgram(program || null);
        loadImage(program?.willowSubIndustryId);
      } catch (error) {
        console.error("Error fetching program:", error);
      } finally {
        setLoading(false); // Stop loading after the fetch
      }
    },
    [loadImage]
  );

  useEffect(() => {
    if (!currentProgram) return;
    const getReviews = async () => {
      const results = await getFSDoc<ReviewRecord>({
        col: Collection.REVIEWS,
        id: currentProgram.providerId,
      });
      setReviews(results);
    };
    getReviews();
  }, [currentProgram, getFSDoc]);

  useEffect(() => {
    const currentPrograms = userType === UserType.STUDENT ? programs : allMatchedPrograms;
    const selectedProgram = currentPrograms.find((program) => program.id === programId);
    if (selectedProgram) {
      setCurrentProgram(selectedProgram);
      setLoading(false); // Program found in memory, stop loading
    } else {
      fetchProgram(programId);
    }
  }, [allMatchedPrograms, programs, programId, userType, fetchProgram, getProgramTrigger]);

  useEffect(() => {
    if (!currentProgram) return;
    const { applyURL, homeURL } = currentProgram;
    const validLink = (url: string) => (url.includes("http") ? url : `https://${url}`);
    if (applyURL && applyURL.length > 3) {
      setApplyLink(validLink(applyURL));
    } else if (homeURL && homeURL.length > 3) {
      setApplyLink(validLink(homeURL));
    }
  }, [currentProgram]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const openWhatIsROI = useCallback(() => {
    setWhatIsROIOpen(true);
  }, []);

  const handleDescriptionModalClose = useCallback(() => {
    setDescriptionModal({
      title: "",
      description: "",
      open: false,
    });
  }, []);

  return {
    currentProgram,
    navigateBack,
    openWhatIsROI,
    whatIsROIOpen,
    setWhatIsROIOpen,
    handleDescriptionModalClose,
    descriptionModal,
    applyLink,
    setDescriptionModal,
    setGetProgramTrigger,
    reviews,
    programImage,
    loading, // Return loading state
  };
};

export default useProgramDetailsDialog;
