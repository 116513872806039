import { useEffect, useState, useCallback, useRef } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  AnswerRecord,
  PersonalityTypeRecord,
  QuestionRecord,
  StudentRecord,
} from "../../../shared/types/types";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection, PageRoute } from "../../../shared/types/enums";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { answersAtom } from "../../recoil/quizAtoms";
import useDetermineQuizResults from "./useDetermineQuizResults";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";
import useGetFSDoc from "../../../shared/hooks/db/useGetFSDoc";
import { parseSinglePersonalityTypeRecord } from "../../../shared/utils/parserUtils";
import { personalityTypeAtom } from "../../../shared/recoil/personalityTypeAtoms";

type Props = {
  loggedInStudent: StudentRecord;
  question: QuestionRecord;
  answer: AnswerRecord | null;
  goForward: () => void;
  isLastQuestion: boolean;
};

const buildAnswerChoices = (question: QuestionRecord, topPick: string, secondPick: string) => {
  const topOption = question.options.find((o) => o.optionId === topPick);
  const secondOption = question.options.find((o) => o.optionId === secondPick);

  return [
    {
      optionId: topOption?.optionId ?? "",
      optionAlignment: topOption?.optionAlignment ?? null,
      choice: 1,
    },
    ...(secondPick
      ? [
          {
            optionId: secondOption?.optionId ?? "",
            optionAlignment: secondOption?.optionAlignment ?? null,
            choice: 2,
          },
        ]
      : []),
  ];
};

const useQuizQuestionManager = ({
  loggedInStudent,
  question,
  answer,
  goForward,
  isLastQuestion,
}: Props) => {
  const [picks, setPicks] = useState({
    topPick: answer?.answerChoices.find((a) => a.choice === 1)?.optionId ?? "",
    secondPick: answer?.answerChoices.find((a) => a.choice === 2)?.optionId ?? "",
  });

  const setAnswers = useSetRecoilState(answersAtom);
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const { addFSDoc } = useAddFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const navigate = useNavigate();
  const { determineQuizResults } = useDetermineQuizResults();
  const { handleError } = useHandleError();
  const { submitLog } = useLogger();
  const { getFSDoc } = useGetFSDoc();
  const setPersonalityType = useSetRecoilState(personalityTypeAtom);
  const [nextDisabled, setNextDisabled] = useState(false);

  const loggedInStudentRef = useRef(loggedInStudent);
  useEffect(() => {
    loggedInStudentRef.current = loggedInStudent;
  }, [loggedInStudent]);

  useEffect(() => {
    setPicks({
      topPick: answer?.answerChoices.find((a) => a.choice === 1)?.optionId ?? "",
      secondPick: answer?.answerChoices.find((a) => a.choice === 2)?.optionId ?? "",
    });
  }, [answer]);

  useEffect(() => {
    setNextDisabled(
      question.questionType === "ms" ? !picks.topPick || !picks.secondPick : !picks.topPick
    );
  }, [picks.secondPick, picks.topPick, question.questionType]);

  const submitQuiz = useCallback(async () => {
    const personalityTypeName = determineQuizResults();

    try {
      const personalityType = await getFSDoc<PersonalityTypeRecord>({
        col: Collection.PERSONALITY_TYPE,
        id: personalityTypeName,
      });
      if (!personalityType) return;
      setPersonalityType(parseSinglePersonalityTypeRecord(personalityType));
      const uniqueOnetCodes: string[] = Array.from(
        new Set(personalityType.recommendedCareers.map((career) => career.onetCode))
      );

      setLoggedInStudent((prev) =>
        prev
          ? {
              ...prev,
              personalityType: personalityTypeName,
              quizComplete: true,
              willowRecommendedJobIds: uniqueOnetCodes,
            }
          : null
      );
      await updateFSDoc({
        col: Collection.STUDENTS,
        id: loggedInStudentRef.current.id,
        data: {
          personalityType: personalityTypeName,
          quizComplete: true,
          willowRecommendedJobIds: uniqueOnetCodes,
        },
      });
      navigate(PageRoute.STUDENT_PROFILE);
      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.SUBMIT_QUIZ,
        changeLog: `Student ${loggedInStudentRef.current.id} submitted their quiz.`,
        file: "useQuizQuestionManager.ts",
      });
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage:
          "An error occurred while submitting your quiz. Please refresh and try again.",
        eventType: LogEventType.SUBMIT_QUIZ_ERROR,
        file: "useQuizQuestionManager.ts",
      });
    }
  }, [
    determineQuizResults,
    getFSDoc,
    handleError,
    navigate,
    setLoggedInStudent,
    setPersonalityType,
    submitLog,
    updateFSDoc,
  ]);

  const buildAnswerChoicesMemo = useCallback(
    (topPick: string, secondPick: string) => {
      return buildAnswerChoices(question, topPick, secondPick);
    },
    [question]
  );

  const onSubmit = useCallback(async () => {
    const answerChoices = buildAnswerChoicesMemo(picks.topPick, picks.secondPick);
    setNextDisabled(true);

    try {
      if (answer) {
        await updateFSDoc({
          col: Collection.ANSWERS,
          id: answer.id,
          data: { answerChoices },
        });
        setAnswers((prev) => prev.map((a) => (a.id === answer.id ? { ...a, answerChoices } : a)));
      } else {
        const newAnswer = {
          studentId: loggedInStudentRef.current.id,
          questionId: question.id,
          districtId: loggedInStudentRef.current.districtId,
          schoolId: loggedInStudentRef.current.schoolId,
          answerChoices,
        };
        const metaData = await addFSDoc<AnswerRecord>({
          col: Collection.ANSWERS,
          data: newAnswer,
        });
        setAnswers((prev) => [...prev, { ...newAnswer, ...metaData }]);
      }

      await updateFSDoc({
        col: Collection.STUDENTS,
        id: loggedInStudentRef.current.id,
        data: { lastQuestionId: question.id },
      });
      setLoggedInStudent((prev) => (prev ? { ...prev, lastQuestionId: question.id } : null));

      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.SUBMIT_QUIZ_ANSWER,
        changeLog: `Student ${loggedInStudentRef.current.id} submitted their answer to question ${question.id}.`,
        file: "useQuizQuestionManager.ts",
      });

      if (isLastQuestion) {
        await submitQuiz();
      }

      setPicks({ topPick: "", secondPick: "" });
      setNextDisabled(false);
      goForward();
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage:
          "An error occurred while submitting your quiz answer. Please refresh and try again.",
        eventType: LogEventType.SUBMIT_QUIZ_ANSWER_ERROR,
        file: "useQuizQuestionManager.ts",
      });
      setNextDisabled(false);
    }
  }, [
    answer,
    question,
    picks.topPick,
    picks.secondPick,
    updateFSDoc,
    setAnswers,
    addFSDoc,
    setLoggedInStudent,
    submitLog,
    isLastQuestion,
    submitQuiz,
    goForward,
    handleError,
    buildAnswerChoicesMemo,
  ]);

  return {
    picks,
    setPicks,
    onSubmit,
    nextDisabled,
  };
};

export default useQuizQuestionManager;
