import { FormikHelpers } from "formik";
import * as Yup from "yup";
import { Collection } from "../../../shared/types/enums";
import { Dispatch, SetStateAction, useCallback } from "react";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";
import { useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import {
  AddressComponents,
  ProviderProgramRecord,
  StudentRecord,
} from "../../../shared/types/types";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useGenerateProgramRecs from "../recommendationAlgorithm/useGenerateProgramRecs";
import { recommendedProgramsAtom } from "../../recoil/recommendedProgramAtoms";

type Props = {
  student: StudentRecord;
  setDescriptionModal: Dispatch<SetStateAction<DescriptionModalType>>;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  mode: "unlock" | "update";
};

const validationSchema = Yup.object({
  gpaValue: Yup.number()
    .required("GPA is required")
    .min(0, "GPA cannot be less than 0")
    .test("max", "GPA cannot be higher than GPA Max", function (value) {
      return !value || !this.parent.gpaMax || value <= this.parent.gpaMax;
    }),
  gpaMax: Yup.number()
    .required("GPA Max is required")
    .min(1, "GPA Max cannot be less than 1")
    .max(10, "GPA Max cannot be higher than 10"),
  act: Yup.number()
    .min(1, "ACT Max cannot be less than 0")
    .max(36, "ACT Max cannot be higher than 36"),
  sat: Yup.number()
    .min(400, "SAT Max cannot be less than 400")
    .max(1600, "SAT Max cannot be higher than 1600"),
  frlStatus: Yup.string().required("FRL Status is required"),
  programTypeForRecommendations: Yup.string().required("Program Type is required"),
  includeOnlineOnly: Yup.string()
    .oneOf(["true", "false"], "Invalid selection")
    .required("Please select an option"),
});

const unlockValidationSchema = Yup.object({
  gpaValue: Yup.number()
    .required("GPA is required")
    .min(0, "GPA cannot be less than 0")
    .test("max", "GPA cannot be higher than GPA Max", function (value) {
      return !value || !this.parent.gpaMax || value <= this.parent.gpaMax;
    }),
  gpaMax: Yup.number()
    .required("GPA Max is required")
    .min(0, "GPA Max cannot be less than 0")
    .max(10, "GPA Max cannot be higher than 10"),
  act: Yup.number()
    .min(1, "ACT Max cannot be less than 0")
    .max(36, "ACT Max cannot be higher than 36"),
  sat: Yup.number()
    .min(400, "SAT Max cannot be less than 400")
    .max(1600, "SAT Max cannot be higher than 1600"),
  frlStatus: Yup.string().required("FRL Status is required"),

  address: Yup.object()
    .shape({
      address: Yup.string().required("Address is required"),
      lat: Yup.number().notOneOf([0], "Please select a valid address"),
      lon: Yup.number().notOneOf([0], "Please select a valid address"),
      city: Yup.string(),
      state: Yup.string(),
      zip: Yup.string(),
      county: Yup.string(),
    })
    .test("is-valid-selection", "Please select a valid address from the list", function (value) {
      return value && value.lat !== 0 && value.lon !== 0;
    }),
  programTypeForRecommendations: Yup.string().required("Program Type is required"),
  includeOnlineOnly: Yup.string()
    .oneOf(["true", "false"], "Invalid selection")
    .required("Please select an option"),
});

type FormType = {
  gpaValue: number | string;
  gpaMax: number | string;
  act: string;
  sat: string;
  frlStatus: string;
  address: AddressComponents;
  statesForRecommendations: string[];
  citiesForRecommendations: string[];
  includeOnlineOnly: string;
  programTypeForRecommendations: string;
};

export type DescriptionModalType = {
  title: string;
  description: string;
  open: boolean;
};

const useCareerUnlockSurveyForm = ({ student, setDialogOpen }: Props) => {
  const { handleError } = useHandleError();
  const { submitLog } = useLogger();
  const { updateFSDoc } = useUpdateFSDoc();
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const { handleGenerate } = useGenerateProgramRecs();
  const setRecommendedPrograms = useSetRecoilState(recommendedProgramsAtom);

  const initialValues: FormType = {
    gpaValue: student.gpaValue || "",
    gpaMax: student.gpaMax || "4",
    act: student.act,
    sat: student.sat,
    frlStatus: student.frlStatus,
    statesForRecommendations: student.statesForRecommendations || [],
    citiesForRecommendations: student.citiesForRecommendations || [],
    includeOnlineOnly: student.includeOnlineOnly ? "true" : "false",
    programTypeForRecommendations: student.programTypeForRecommendations || "",
    address: student.address || {
      address: "",
      lat: 0,
      lon: 0,
      city: "",
      state: "",
      zip: "",
      county: "",
    },
  };

  const handleSubmit = useCallback(
    async (values: FormType, { setSubmitting }: FormikHelpers<FormType>) => {
      if (!student) return;
      try {
        const programMatches: ProviderProgramRecord[] = await handleGenerate({
          gpaMax: values.gpaMax === "" ? 4 : parseFloat(String(values.gpaMax)),
          gpaValue: values.gpaValue === "" ? 3 : parseFloat(String(values.gpaValue)),
          programTypeForRecommendations: values.programTypeForRecommendations,
          includeOnlineOnly: values.includeOnlineOnly === "true",
          statesForRecommendations: values.statesForRecommendations,
          citiesForRecommendations: values.citiesForRecommendations,
          onetCodes: [...student.willowRecommendedJobIds, ...student.favoriteJobIds],
          removedProgramIds: student.removedProgramIds,
          dislikedJobIds: student.dislikedJobIds,
          act: values.act,
        });
        setRecommendedPrograms(programMatches);
        const programMatchIds = programMatches.map((program) => program.id);
        const updatedValues = {
          ...values,
          gpaMax: values.gpaMax === "" ? 4 : parseFloat(String(values.gpaMax)),
          gpaValue: values.gpaValue === "" ? 3 : parseFloat(String(values.gpaValue)),
          includeOnlineOnly: values.includeOnlineOnly === "true",
          setupComplete: true,
          willowRecommendedProgramIds: programMatchIds,
        };
        await updateFSDoc({
          col: Collection.STUDENTS,
          data: updatedValues,
          id: student.id,
        });
        setLoggedInStudent((pV) => (pV ? { ...pV, ...updatedValues } : null));
        submitLog({
          severity: LogSeverity.INFO,
          eventType: LogEventType.CAREER_UNLOCK_SURVEY_SUBMITTED,
          changeLog: `User ${student.id} submitted the career unlock survey successfully`,
          file: "useCareerUnlockSurveyForm.ts",
          error: null,
        });
      } catch (error) {
        handleError({
          error,
          snackbarMessage:
            "There was an error updating your profile, please refresh and try again.",
          eventType: LogEventType.CAREER_UNLOCK_SURVEY_ERROR,
          file: "useCareerUnlockSurveyForm.ts",
        });
      }
      setSubmitting(false);
      setDialogOpen(false);
    },
    [
      student,
      setDialogOpen,
      handleGenerate,
      setRecommendedPrograms,
      updateFSDoc,
      setLoggedInStudent,
      submitLog,
      handleError,
    ]
  );

  return { initialValues, validationSchema, unlockValidationSchema, handleSubmit };
};

export default useCareerUnlockSurveyForm;
