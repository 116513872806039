import { useState } from "react";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Color } from "../../../shared/types/enums";
import blankHeartedProgramsImage from "../../../shared/assets/icons/blank-hearted-programs-image.svg";
import { Box, Button, Typography } from "@mui/material";
import { ProviderProgramRecord } from "../../../shared/types/types";
import RecommendProgramDialog from "../programCard/RecommendProgramDialog";
import StaffProgramTitleProviderCard from "./StaffProgramTitleProviderCard";
type Props = {
  handleSwitchMode: () => void;
  recommendedPrograms: ProviderProgramRecord[];
  setSelectedProgramDetail: (program: ProviderProgramRecord | null) => void;
};

const RecommendedPrograms = ({
  handleSwitchMode,
  recommendedPrograms,
  setSelectedProgramDetail,
}: Props) => {
  return (
    <>
      <DisplayBox
        title="Recommended Programs"
        titleSX={{ color: Color.CHALKBOARD }}
        actionButton={<Button onClick={handleSwitchMode}>Switch to Bookmarked Programs</Button>}
      >
        {recommendedPrograms.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 1.5,
              height: "300px",
            }}
          >
            <img src={blankHeartedProgramsImage} alt="icon" width="200px" />
            <Typography variant="body1" color={Color.GRAY_800}>
              The student doesn't have any recommended programs, yet.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              height: "300px",
              overflowY: "scroll",
              py: 1,
            }}
          >
            {recommendedPrograms.map((program, index: number) => (
              <StaffProgramTitleProviderCard
                key={index}
                providerProgram={program}
                setSelectedProgramDetail={setSelectedProgramDetail}
              />
            ))}
          </Box>
        )}
      </DisplayBox>

      <RecommendProgramDialog />
    </>
  );
};
export default RecommendedPrograms;
