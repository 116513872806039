import { FunctionName } from "../../shared/types/enums";
import {
  ChatEntry,
  PersonalityTypeRecord,
  SchoolRecord,
  StudentRecord,
} from "../../shared/types/types";
import { fetchData } from "../../shared/utils/fetchUtils";

type GenerateAlmaProps = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  personalityType: PersonalityTypeRecord | null;
};

const buildOpening = (loggedInStudent: StudentRecord) => {
  return `
**Your Role:**
You are Alma, an AI post-secondary counselor for a high school student named ${loggedInStudent.firstName}, who is using an app called Willow. Willow is designed to help students like ${loggedInStudent.firstName} find high-quality career paths and post-secondary programs, including colleges and professional skill development opportunities.

**Tone and Approach:**
Adopt a "warm-demanding" tone, striking a careful balance between being firm in guiding ${loggedInStudent.firstName} towards high-quality options with strong completion rates and ROI, while also being supportive, optimistic, and encouraging. You should be authoritative yet nurturing, helping ${loggedInStudent.firstName} make well-informed decisions by presenting data-driven insights while boosting their confidence in their ability to succeed.
`;
};

const buildContext = (
  loggedInStudent: StudentRecord,
  personalityType: PersonalityTypeRecord | null,
  school: SchoolRecord | undefined
) => {
  let context = `
**Context and Personalization:**

**Student Background:**

- ${loggedInStudent.firstName} is an ${loggedInStudent.gradeLevel} student at ${school?.name} with a GPA of ${loggedInStudent.gpaValue} out of ${loggedInStudent.gpaMax}.
- ${loggedInStudent.firstName}'s primary goal after high school is "${loggedInStudent.myWhy}".
- They also shared the following: "${loggedInStudent.whatDescribesMe}".`;

  if (personalityType) {
    context += `**Personality Insights:**

- ${
      loggedInStudent.firstName
    } took a personality assessment inspired by Holland Codes and The Big 5 Personality Types. ${
      loggedInStudent.firstName
    } has an ${personalityType.title} personality, characterized by ${
      personalityType.shortDescription
    }.
- Their recommended careers include: ${personalityType.recommendedCareers
      .map((career) => career.title)
      .join(", ")}.`;
  }

  context += `**Geographic Preferences:** `;

  if (
    loggedInStudent.statesForRecommendations.length === 0 &&
    loggedInStudent.citiesForRecommendations.length === 0
  ) {
    context += `${loggedInStudent.firstName} is open to colleges and/or professional programs anywhere in the US. `;
  } else if (
    loggedInStudent.statesForRecommendations.length > 0 &&
    loggedInStudent.citiesForRecommendations.length === 0
  ) {
    context += `${
      loggedInStudent.firstName
    } is open to colleges and/or professional programs in ${loggedInStudent.statesForRecommendations.join(
      ", "
    )}. `;
  } else {
    context += `${
      loggedInStudent.firstName
    } is open to colleges and/or professional programs in ${loggedInStudent.citiesForRecommendations.join(
      ", "
    )}.
`;
  }
  return context;
};

const buildExamples = (loggedInStudent: StudentRecord) => {
  return `
**Guidance Structure:**

**Personalized Communication:**

- Address ${loggedInStudent.firstName} by their name in every interaction.
- Provide tailored advice based on${loggedInStudent.firstName}'s goals, personality, and preferences listed above.
- Highlight programs and careers that align with ${loggedInStudent.firstName}'s strengths and aspirations.

**Warm-Demanding Examples:**

- **Warm:** "${loggedInStudent.firstName}, your aspiration to achieve financial success through education is admirable. I’m confident we can find a path that not only meets your goals but exceeds them."
- **Demanding:** "While it's important to consider cost, let's prioritize programs with strong outcomes and high completion rates to ensure your education is a valuable investment."

**Information-Driven Recommendations:**

- Encourage ${loggedInStudent.firstName} to carefully consider their options by presenting data on program success rates, alumni outcomes, and ROI.
- Ask if ${loggedInStudent.firstName} would like recommendations for specific programs or colleges that align with their interests and goals.

**Tailored Questioning:**

- When asking open-ended questions, pose one question at a time to guide ${loggedInStudent.firstName} through the decision-making process.
- After providing information, ask if more details would be helpful and offer to recommend specific programs.

**Boundaries:**

- Maintain focus on topics related to college, career, and future planning. Politely steer conversations back to relevant topics if they deviate.

**Objective:**

- Your goal is to ensure that ${loggedInStudent.firstName} leaves each interaction with a clear, actionable next step towards achieving their post-secondary goals. Emphasize well-informed decisions and the personal capability to succeed.
`;
};

const generateAlmaContextPrompt = ({
  loggedInStudent,
  schools,
  personalityType,
}: GenerateAlmaProps) => {
  const school = schools.find((school) => school.id === loggedInStudent.schoolId);
  let fullPrompt = buildOpening(loggedInStudent);
  fullPrompt += buildContext(loggedInStudent, personalityType, school);
  fullPrompt += buildExamples(loggedInStudent);
  return fullPrompt;
};

type StartingPromptProps = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  personalityType: PersonalityTypeRecord | null;
};

export const generateStartingPrompt = ({
  loggedInStudent,
  schools,
  personalityType,
}: StartingPromptProps) => {
  const startingHistory: ChatEntry[] = [
    {
      role: "user",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: generateAlmaContextPrompt({
            loggedInStudent,
            schools,
            personalityType,
          }),
        },
      ],
    },
    {
      role: "model",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: `Hello ${loggedInStudent.firstName}! I'm Alma, your career and college coach. I'm here to help you find your best-fit, best-quality next steps in postsecondary education. How can I help you today?`,
        },
      ],
    },
  ];
  return startingHistory;
};

const chatMessageStyle = {
  padding: "10px",
  margin: "10px",
  borderRadius: "10px",
  maxWidth: "70%",
  width: "fit-content",
  fontFamily: "poppins",
  fontSize: "14px",
};

export const formatChatBackground = (role: string) => {
  return {
    user: {
      ...chatMessageStyle,
      backgroundColor: "#EBF8FF",
    },
    model: {
      ...chatMessageStyle,
      backgroundColor: "#EDFCF9",
    },
  }[role];
};

export const STARTER_PROMPTS = [
  {
    text: "Picking a Career",
    svg: "almaGrowth",
    prompt:
      "I need help picking out a career path, can you ask me some questions that could help me figure out a good career path, and then recommend some careers that might be a good fit for me?",
  },
  {
    text: "Writing a Great College Essay",
    svg: "almaEdit",
    prompt:
      "I need help writing a college essay, can you give me some tips on how to write a great essay that will help me stand out to colleges?",
  },
  {
    text: "Picking a College",
    svg: "almaSchool",
    prompt:
      "I need help picking out a college, can you ask me some questions that could help me figure out a good college fit, and then recommend some colleges that might be a good fit for me?",
  },
  {
    text: "Deciding on a Major",
    svg: "almaGraduation",
    prompt:
      "I need help deciding on a major, can you ask me some questions that could help me figure out a good major fit, and then recommend some majors that might be a good fit for me?",
  },
  {
    text: "Getting Ready for the ACT / SAT",
    svg: "almaBook",
    prompt:
      "I need help getting ready for the ACT / SAT, can you give me some tips on how to study for the test and when the deadlines are?",
  },
  {
    text: "Study Tips",
    svg: "almaLamp",
    prompt:
      "I need help with studying, can you give me some tips on how to study better and manage my time?",
  },
  {
    text: "Paying for College",
    svg: "almaMoney",
    prompt:
      "I need help paying for college, can you tell me about scholarships, grants, loans, and other ways to get financial aid?",
  },
  {
    text: "Getting an Internship",
    svg: "almaPerson",
    prompt:
      "I need help getting an internship, can you tell me about internships, volunteering, or how I can job shadow someone?",
  },
];

//helper that turns a message into a new chat entrys
export const buildChatEntry = (newMessage: string) => {
  const entry: ChatEntry = {
    role: "user",
    parts: [{ text: newMessage }],
    timestamp: new Date().toISOString(),
  };
  return entry;
};

type getAlmaResponseProps = {
  chatHistoryForAI: ChatEntry[];
  newEntry: ChatEntry;
  contextToAdd: string;
};

//helper that gets alma's reply and returns it as a chat entry type
export const getAlmaResponse = async ({
  chatHistoryForAI,
  newEntry,
  contextToAdd,
}: getAlmaResponseProps) => {
  const response = await fetchData({
    functionName: FunctionName.RUN_STUDENT_ALMA,
    payload: {
      chatHistory: chatHistoryForAI,
      message: newEntry.parts[0].text,
      contextToAdd,
    },
  });
  const textResponse = await response.json();
  const text = response
    ? await textResponse.text
    : "I'm sorry, I'm not able to respond to that right now. Please try again later.";
  const responseToSave: ChatEntry = {
    role: "model",
    timestamp: new Date().toISOString(),
    parts: [
      {
        text: text,
      },
    ],
  };
  return responseToSave;
};
