import { Box, Dialog } from "@mui/material";
import ProgramFilterAndSearchBar from "../../../students/components/explorePrograms/filters/ProgramFilterAndSearchBar";
import { FilterProps, SearchedProvider } from "../../types/types";
import { Color } from "../../types/enums";
import { useEffect, useState } from "react";
import { initialFiltersValues } from "../../utils/initialProgramFiltersUtil";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  filters: FilterProps;
  searchedProviders: SearchedProvider[];
  handleFilterButtonClick: (searchedProviders: SearchedProvider[], filters: FilterProps) => void;
};

const FiltersDialog = ({
  open,
  setOpen,
  filters,
  searchedProviders,
  handleFilterButtonClick,
}: Props) => {
  const [dialogSearchedProviders, setDialogSearchedProviders] = useState<SearchedProvider[]>([]);
  const [dialogFilters, setDialogFilters] = useState<FilterProps>(filters);

  useEffect(() => {
    if (open) {
      setDialogSearchedProviders(searchedProviders);
      setDialogFilters(filters);
    }
  }, [open, searchedProviders, filters]);

  const handleResetButtonClick = () => {
    setDialogSearchedProviders([]);
    setDialogFilters(initialFiltersValues);
  }

  const handleSearchButtonClick = () => {
    handleFilterButtonClick(dialogSearchedProviders, dialogFilters);
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        {dialogSearchedProviders && dialogFilters && (
          <Box sx={{ background: Color.GRAY_50, p: "20px" }}>
            <ProgramFilterAndSearchBar
              showReset={true}
              filters={dialogFilters}
              setFilters={setDialogFilters}
              handleResetButtonClick={handleResetButtonClick}
              handleFilterButtonClick={handleSearchButtonClick}
              searchedProviders={dialogSearchedProviders}
              setSearchedProviders={setDialogSearchedProviders}
            />
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default FiltersDialog;
