import { useEffect, useState } from "react";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import { selectedJobForRecommendationsAtom } from "../../recoil/careerAtoms";
import { StudentRecord } from "../../../shared/types/types";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType, LogSeverity } from "../../../shared/types/logEnums";

const useRecommendJobDialog = () => {
  const [loading, setLoading] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<StudentRecord[]>([]);
  const [selectedJobForRecommendations, setSelectedJobForRecommendations] =
    useRecoilState(selectedJobForRecommendationsAtom);
  const setAllSchoolStudents = useSetRecoilState(allSchoolStudentsAtom);
  const students = useRecoilValue(allSchoolStudentsAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { handleError } = useHandleError();
  const { submitLog } = useLogger();

  useEffect(() => {
    const alreadyRecommendedStudents = students.filter((student) => {
      return student.staffRecommendedJobIds?.includes(
        selectedJobForRecommendations?.id ?? ""
      );
    });
    setSelectedStudents(alreadyRecommendedStudents);
  }, [selectedJobForRecommendations, students]);

  const handleStudentsChange = (_: any, value: StudentRecord[]) => {
    setSelectedStudents(value);
  };

  const handleSave = async () => {
    if (!selectedJobForRecommendations) return;

    setLoading(true);

    const currentJobId = selectedJobForRecommendations.id;
    const updatedStudents = students.map((student) => {
      const isSelected = selectedStudents.some(
        (selectedStudent) => selectedStudent.id === student.id
      );
      const hasJobId = student.staffRecommendedJobIds?.includes(currentJobId);

      if (isSelected && !hasJobId) {
        return {
          ...student,
          staffRecommendedJobIds: [
            ...(student.staffRecommendedJobIds || []),
            currentJobId,
          ],
        };
      } else if (!isSelected && hasJobId) {
        return {
          ...student,
          staffRecommendedJobIds: student.staffRecommendedJobIds.filter(
            (id) => id !== currentJobId
          ),
        };
      } else {
        return student;
      }
    });

    try {
      const updatePromises = updatedStudents.map((student) =>
        updateFSDoc({
          col: Collection.STUDENTS,
          id: student.id,
          data: { staffRecommendedJobIds: student.staffRecommendedJobIds },
        })
      );
      await Promise.all(updatePromises);

      submitLog({
        severity: LogSeverity.INFO,
        eventType: LogEventType.STAFF_RECOMMENDED_CAREER,
        changeLog: `Staff recommened the career ${currentJobId} successfully`,
        file: "useRecommendJobDialog.ts",
        error: null,
      });
    } catch (error: any) {
      handleError({
        error,
        snackbarMessage:
          "There was an error recommendation the career, please refresh and try again.",
        eventType: LogEventType.STAFF_RECOMMEND_CAREER_ERROR,
        file: "useRecommendJobDialog.ts",
      });
    }

    setAllSchoolStudents(updatedStudents);
    setSelectedJobForRecommendations(null);
    setLoading(false);
  };

  return {
    loading,
    students,
    selectedStudents,
    handleStudentsChange,
    selectedJobForRecommendations,
    setSelectedJobForRecommendations,
    handleSave,
  };
};

export default useRecommendJobDialog;
