import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import { FunctionName, UserType } from "../../types/enums";
import { ProviderProgramRecord, ProviderRecord } from "../../types/types";
import { fetchData } from "../../utils/fetchUtils";

type Props = {
  providerId: string;
};

const useProviderDetailsDialog = ({ providerId }: Props) => {
  const navigate = useNavigate();
  const [currentProvider, setCurrentProvider] = useState<ProviderRecord | null>(null);
  const [programsByProvider, setProgramsByProvider] = useState<ProviderProgramRecord[]>([]);
  const [editProviderOpen, setEditProviderOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [getProviderTrigger, setGetProviderTrigger] = useState(false);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  useEffect(() => {
    if (!loggedInStaff) return;
    if (loggedInStaff.userType === UserType.STAFF) {
      setCanEdit(true);
    }
  }, [loggedInStaff]);

  useEffect(() => {
    const getProvider = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROVIDER_BY_ID,
        payload: { providerId: providerId },
      });
      const { provider } = (await results.json()) as {
        provider: ProviderRecord;
      };
      setCurrentProvider(provider ? provider : null);
    };

    const getProgramsByProviderId = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAMS_BY_PROVIDER_ID,
        payload: { providerId: providerId },
      });
      const matchedResults = await results.json();
      setProgramsByProvider(matchedResults.programs);
    };

    getProvider();
    getProgramsByProviderId();
  }, [providerId, getProviderTrigger]);

  const navigateBack = () => {
    navigate(-1);
  };

  return {
    currentProvider,
    programsByProvider,
    navigateBack,
    editProviderOpen,
    setEditProviderOpen,
    canEdit,
    setGetProviderTrigger,
  };
};

export default useProviderDetailsDialog;