import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { Suspense, useState } from "react";
import StaffTable from "./StaffTable";
import { StaffRecord } from "../../../shared/types/types";
import ManageStaffDialog from "./ManageStaffDialog";
import { useRecoilValue } from "recoil";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import { BLANK_STAFF_FORM } from "../../../shared/utils/blankUtils";

const SchoolSettingsContainer = () => {
  const [manageStaffOpen, setManageStaffOpen] = useState(false);
  const [staffForm, setStaffForm] = useState<StaffRecord>({ ...BLANK_STAFF_FORM });
  const students = useRecoilValue(allSchoolStudentsAtom);

  const handleNewStaffClick = () => {
    setStaffForm({ ...BLANK_STAFF_FORM });
    setManageStaffOpen(true);
  };

  return (
    <>
      {students ? (
        <>
          <Box sx={{ p: 2 }}>
            <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  p: 1,
                }}
              >
                <Typography variant="h6">Staff</Typography>
                <Box>
                  <Button variant="outlined" onClick={handleNewStaffClick}>
                    Add Staff
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Suspense fallback={<LoadingBlock size={80} />}>
                <StaffTable setStaffForm={setStaffForm} setManageStaffOpen={setManageStaffOpen} />
              </Suspense>
            </Paper>
          </Box>
        </>
      ) : (
        <LoadingBlock size={80} />
      )}
      <ManageStaffDialog
        open={manageStaffOpen}
        setOpen={setManageStaffOpen}
        staffForm={staffForm}
        setStaffForm={setStaffForm}
      />
    </>
  );
};

export default SchoolSettingsContainer;
